@import "../assets/css/mixin.scss";
.profileLeft {
  width: 400px;
  @include respond-to(media-md) {
    width: 300px;
  }
  @include respond-to(media-sm) {
    width: 100%;
  }
}
.profileImg {
  position: relative;
  &.schoolImg {
    height: 250px;
    border: 1px solid #ddd;
    border-radius: $borderRad;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      width: auto;
    }
  }
  img {
    width: 100%;
    border-radius: $borderRad;
  }
  .uploadPhoto {
    background: #3da4b4;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 3px solid #fff;
    color: #fff;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .file {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      opacity: 0;
    }
  }
}
.userInfo {
  li {
    background: #f8f8f8;
    min-height: 60px;
    border-radius: $borderRad;
    position: relative;
    font-weight: 600;
    padding: 8px 10px 8px 20px;
    word-break: break-word;
    &:before {
      content: "";
      background: #ff854c;
      width: 3px;
      height: 60%;
      position: absolute;
      left: 0;
      top: 20%;
    }
    .icon {
      color: #ff854c;
      font-size: 24px;
      min-width: 24px;
    }
  }
}
.profileRight {
  width: calc(100% - 430px);
  @include respond-to(media-md) {
    width: calc(100% - 320px);
  }
  @include respond-to(media-sm) {
    width: 100%;
    margin-top: 20px;
  }
}
.smallBtn {
  &.small {
    font-size: 14px;
    height: 34px;
    border-radius: 6px;
    min-width: inherit;
  }
}
.contentBox {
  line-height: 26px;
}
