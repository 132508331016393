@import "../assets/css/mixin.scss";
a.addVehicle {
  background: $primary;
  height: 50px;
  font-size: 15px;
  font-weight: 500;
  border-radius: $borderRad;
  padding: 0 25px;
  color: $whtClr;
  &:hover {
    background: $darkGray;
  }
  @include respond-to(media-sm) {
    display: none !important;
  }
}
a.notification {
  border: 1px solid #c5c5c5;
  width: 50px;
  height: 50px;
  position: relative;
  svg {
    font-size: 22px;
  }
  .badge {
    background: #ff0000;
    width: 20px;
    height: 20px;
    color: $whtClr;
    font-size: 12px;
    position: absolute;
    right: -4px;
    top: -4px;
  }
  &:hover {
    background: $darkGray;
    border-color: $darkGray;
    color: $whtClr;
  }
}
.acLinkBox {
  .acLink {
    font-size: 15px;
    font-weight: 500;
    background: none;
    padding: 0;
    border: none;
    color: #232e35;
    &::after {
      display: none;
    }
    &:hover {
      background: none;
    }
    img {
      width: 50px;
      height: 50px;
      border: 1px solid #ccc;
      padding: 1px;
    }
    &:hover {
      color: $primary;
    }
    @include respond-to(media-lg) {
      span {
        display: none;
      }
    }
  }
  .dropdown-menu.show {
    margin-top: 15px;
    padding: 0;
    left: inherit !important;
    right: 0 !important;
    min-width: 200px;
    a {
      padding: 0;
      border-bottom: 1px solid #eee;
      padding: 10px 15px;
      display: block;
      font-size: 14px;
      .icon {
        font-size: 20px;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}
.dbLogoMob {
  display: none;
  @include respond-to(media-lg) {
    display: block;
  }
  img {
    height: 56px;
  }
}
.dbheaderRight {
  @include respond-to(media-lg) {
    padding-right: 42px;
    width: 100%;
  }
}


.notificationBox {position: relative;
  .notificationPoup {position: absolute; right: 0; top: 100%; width: 300px; background: #fff; box-shadow: 0 0 10px rgba(0,0,0,.2); margin-top: 15px; max-height: 260px; overflow: auto;
    &::-webkit-scrollbar {width: 6px; height: 6px;}
    &::-webkit-scrollbar-track { background: #e5e5e5; }
    &::-webkit-scrollbar-thumb { background: #ccc; }
    &::-webkit-scrollbar-thumb:hover { background: #000;}
    @include respond-to(media-sm) {right: -100px;}
    ul {
      li {border-bottom: 1px solid #eee;
        a {padding: 10px 15px; display: block; font-size: 14px;
          span {display: block; color: #FF0000; font-size: 16px; padding-bottom: 5px;}
          &:hover {background: #f4f4f4;}
        }
      }
    }
  }
}