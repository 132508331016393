@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import './mixin.scss';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");



* {
    margin: 0;
    padding: 0;
    outline: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    list-style: none;
}

body {
    font-size: 16px;
    line-height: normal;
    color: #232E35;
    font-family: 'Inter', sans-serif;

    @include respond-to(media-md) {
        font-size: 15px;
    }
}

a {
    outline: none;
    text-decoration: none;
    color: #232E35;
    -webkit-transition: ease-in-out .3s;
    -moz-transition: ease-in-out .3s;
    -ms-transition: ease-in-out .3s;
    -o-transition: ease-in-out .3s;
    transition: ease-in-out .3s;
    cursor: pointer;
}

a:hover {
    text-decoration: none;
    color: #000;
}

input,
textarea,
select {
    font-size: 14px;
    color: #232E35;
    vertical-align: middle;
    font-family: 'Inter', sans-serif;
    outline: none;
    resize: none;
}

input[type="button"],
input[type="submit"],
button {
    -webkit-transition: ease-in-out .3s;
    -moz-transition: ease-in-out .3s;
    -ms-transition: ease-in-out .3s;
    -o-transition: ease-in-out .3s;
    transition: ease-in-out .3s;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
}

select {
    appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
}

select::-ms-expand {
    display: none;
}

.transition {
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    -ms-transition: ease-in-out .5s;
    -o-transition: ease-in-out .5s;
    transition: ease-in-out .5s;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

.checkbox {
    .form-check-input {
        width: 26px;
        height: 26px;
        background: #fff;
        border: 1px solid #BDBDBD;
        border-radius: 4px;

        &:checked {
            background: $primary url(../images/check_icon.png) no-repeat center;
            border-color: $primary;
            color: #fff;
        }
    }
}

.btn-primary {
    background: $primary;
    border-color: $primary;

    &:hover {
        background: $darkGray;
        border-color: $darkGray;
    }
}

.modal-dialog {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 550px;
    margin: 0 auto;
    padding: 0 15px;

    .modal-content {
        box-shadow: 0 0 20px rgba(0, 0, 0, .2);
        border: none;
    }

    .closePopup {
        position: absolute;
        right: 10px;
        top: 10px;
        background: none;
        border: none;
        color: #000;
        padding: 0;
        height: auto;
        line-height: normal;
        z-index: 1000;

        &:hover {
            background: $primary;
            color: #fff;
        }

        svg {
            font-size: 26px;
        }
    }
}

.modal-backdrop.fade {
    background: rgba(255, 255, 255, .9);
    opacity: 1;
}

.formInput {
    width: 100%;
    height: 55px;
    border: 1px solid #D6D6D6;
    border-radius: $borderRad;
    padding: 0 15px;
    font-size: 15px;
    font-weight: 500;

    @include respond-to(media-sm) {
        height: 46px;
        border-radius: 6px;
    }

    &:read-only {
        background: #f4f4f4;
    }
}

textarea.formInput {
    height: auto;
    padding: 15px;
    line-height: 24px;
}

.formSelect {
    width: 100%;
    height: 55px;
    border: 1px solid #D6D6D6;
    border-radius: $borderRad;
    padding: 0 35px 0 15px;
    font-size: 15px;
    font-weight: 500;
    background: #fff url(../images/angle-right.svg) no-repeat right 10px center;

    @include respond-to(media-sm) {
        height: 46px;
        border-radius: 6px;
    }
}

.dbCon {
    padding: 85px 0 0 300px;

    @include respond-to(media-lg) {
        padding: 85px 0 0 0;
    }
}

.dbRight {
    padding: 20px;
    width: 100%;

    @include respond-to(media-lg) {
        padding: 15px;
    }
}

.dbHeader {
    border-bottom: 2px solid #ededed;
    height: 85px;
    background: #fff;
    position: fixed;
    z-index: 101;
    right: 20px;
    top: 0;
    width: calc(100% - 340px);

    @include respond-to(media-lg) {
        width: calc(100% - 30px);
        right: 15px;
    }
}

.dbHd {
    font-size: 24px;
    font-weight: 700;

    @include respond-to(media-lg) {
        display: none;
    }
}

.dbSubHd {
    font-size: 20px;
    font-weight: 600;

    @include respond-to(media-lg) {
        font-size: 18px;
    }
}

.table {
    border: 1px solid #DEDEDE;
    border-radius: $borderRad;
    overflow: auto;

    table {
        width: 100%;

        th {
            background: #232E35;
            font-size: 15px;
            font-weight: 400;
            color: #fff;
            padding: 15px 10px;

            @include respond-to(media-sm) {
                padding: 10px;
                font-size: 14px;
            }
        }

        td {
            background: #F4F4F4;
            font-size: 15px;
            padding: 20px 10px;
            border: none;

            @include respond-to(media-sm) {
                padding: 10px;
                font-size: 14px;
            }

            .status {
                display: inline-flex;
                align-items: center;
                white-space: nowrap;
                background: #0188FF;
                height: 30px;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
                font-weight: 500;
                padding: 0 15px;

                &.pickupCom {
                    background: #44B678;
                }

                &.offDuty {
                    background: #C7C7C7;
                }

                &.idle {
                    background: #C7C7C7;
                }

                &.unavailable {
                    background: #FEB602;
                }

                &.running {
                    background: #44B678;
                }
            }

            .call {
                span {
                    font-weight: 600;
                }
            }
        }

        tr:nth-child(2n) {
            td {
                background: #fff;
            }
        }
    }
}

.paging {
    li {
        margin-right: 10px;

        a {
            height: 32px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            width: 32px;
            border: 1px solid #979797;
            color: #979797;
            font-size: 13px;
            font-weight: 500;

            &:hover {
                border-color: $primary !important;
                background: $primary !important;
                color: $whtClr !important;
            }
        }

        &.active {
            a {
                border-color: $primary !important;
                background: $primary !important;
                color: $whtClr;
            }
        }

        &:first-child,
        &:last-child {
            a {
                background: #EFEFEF;
                border-color: #EFEFEF;
                width: auto;
                padding: 0 15px;
                color: #000;

                &:hover {
                    background: $primary;
                }
            }
        }
    }
}

.smallSelect {
    width: 150px;
    border: 1px solid #EAEAE1;
    border-radius: 6px;
    padding: 6px 20px 6px 10px;
    color: #656C71;
    font-size: 14px;
    background: #F8F8F8 url(../images/angle-right.svg) no-repeat right 10px center;
    position: relative;
}

.searchBox {
    width: 350px;
    border: 1px solid #EAEAE1;
    border-radius: 6px;
    height: 31px;
    padding: 0 30px 0 10px;
    color: #656C71;
    font-size: 14px;
    background: #F8F8F8;
    position: relative;

    .input {
        width: 100%;
        padding: 0;
        height: 29px;
        background: none;
        border: none;
    }

    .searchBtn {
        position: absolute;
        right: 10px;
        top: 0;
        padding: 0;
        background: none;
        color: #333;
        border: none;

        &:hover {
            background: none;
            color: #333;
        }
    }
}

.total {
    font-weight: 600;
}

.actionBtn {
    button {
        border: 1px solid #C5C5C5;
        background: #fff;
        width: 40px;
        height: 40px;
        color: #666;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: $primary;
            border-color: $primary;
        }

        &.iconBtn {
            svg {
                width: 20px;
                ;
                height: 20px;
            }

            &:hover {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}

.vehilcleFilter {
    margin: -5px;

    li {
        padding: 5px;

        @include respond-to(media-md) {
            width: 20%;

            .smallSelect {
                width: 100%;
            }

            .smallInput {
                width: 100%;
            }

            &:first-child {
                width: 40%;

                .searchBox {
                    width: 100%;
                }
            }
        }

        @include respond-to(media-xs) {
            width: 33.33%;

            &:first-child {
                width: 100%;
            }
        }
    }
}

.filterBox {
    @include respond-to(media-md) {
        width: 100%;
    }
}

.errorIcon {
    font-size: 50px;
    color: #ff0000;
}

.smallBtn {
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    border-radius: $borderRad;
    padding: 0 20px;
    min-width: 140px;
    text-align: center;

    &.cancel {
        background: $darkGray;
        border-color: $darkGray;
    }

    &.full {
        width: 100%;
    }

    @include respond-to(media-xs) {
        height: 40px;
        min-width: 110px;
        border-radius: 8px;
    }
}

.deleteHd {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;

    @include respond-to(media-xs) {
        font-size: 22px;
        line-height: 30px;
    }
}

.form {
    margin: -10px;

    @include respond-to(media-md) {
        margin: -5px;
    }

    li {
        padding: 10px;

        @include respond-to(media-md) {
            padding: 5px;
        }

        .filedHd {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;

            @include respond-to(media-md) {
                font-size: 14px;
            }
        }
    }
}

.uplaodBox {
    width: 100%;
    overflow: hidden;
    height: 55px;
    border: 1px solid #D6D6D6;
    border-radius: $borderRad;
    padding: 7px;
    font-size: 15px;
    font-weight: 500;
    position: relative;

    .btn {
        background: #363D4D;
        border: none;
        padding: 0 15px;
        height: 38px;
        position: relative;
        overflow: hidden;
        min-width: 80px;

        .file {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 32px;
            opacity: 0;
        }
    }
}

.checkIcon {
    font-size: 50px;
    color: #189000;
}

.whtBox {
    background: #FFFFFF;
    box-shadow: 0px 6px 20px #0000000D;
    border: 1px solid #EAEAE1;
    border-radius: $borderRad;

    &.fullHeight {
        height: 100%;
    }
}

.addDriver {
    background: #FF854C;
    font-size: 14px;
    padding: 0 15px;
    height: 31px;
    justify-content: center;
    border-radius: 5px;
    color: white;
}

.timeIcon {
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -10px;
    font-size: 20px;
}

.infoIcon {
    font-size: 22px;
    color: #FF854C;
}

.driverInfoBot {
    border-top: 1px solid #dbdbdb;

    ul {
        margin: -10px;

        li {
            padding: 10px;
            font-weight: 600;

            span {
                font-weight: 400;
            }
        }
    }
}

.driverInfoPopup {
    .modal-dialog {
        max-width: 650px;
    }
}

.addbankDetails {
    .modal-dialog {
        max-width: 800px;
    }
}

.tabs {
    border-bottom: 1px solid #ddd;

    a {
        color: #232E35;
        font-weight: 600;
        padding: 15px 20px;
        position: relative;

        @include respond-to(media-md) {
            padding: 15px 10px;
            font-size: 14px;
        }

        &.active {
            background: none !important;
            color: #FF854C !important;

            &:before {
                content: '';
                height: 4px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: -1px;
                background: #FF854C;
            }
        }
    }
}

.smallInput {
    width: 150px;
    border: 1px solid #EAEAE1;
    border-radius: 6px;
    padding: 6px 20px 6px 10px;
    color: #656C71;
    font-size: 14px;
    background: #F8F8F8;
}

.smallDatepicker {
    input {
        width: 150px;
        border: 1px solid #EAEAE1;
        border-radius: 6px;
        padding: 6px 25px 6px 10px;
        color: #656C71;
        font-size: 14px;
        background: #F8F8F8;
    }

    .icon {
        position: absolute;
        right: 5px;
        top: 8px;
    }
}

.transactionsFilter {
    margin: -5px;

    li {
        padding: 5px;

        @include respond-to(media-md) {
            width: 25%;

            .smallSelect {
                width: 100%;
            }

            .smallInput {
                width: 100%;
            }

            .smallDatepicker input {
                width: 100%;
            }

            &:first-child {
                width: 50%;

                .searchBox {
                    width: 100%;
                }
            }
        }

        @include respond-to(media-xs) {
            width: 50%;

            &:first-child {
                width: 100%;

                &.date {
                    width: 50%;
                }
            }
        }
    }
}

.react-datepicker-wrapper {
    display: block !important;
}

.updatePW {
    width: 500px;

    @include respond-to(media-md) {
        width: 60%;
        max-width: 400px;
    }

    @include respond-to(media-xs) {
        width: 100%;
        max-width: 100%;
    }
}

.PWHint {
    font-size: 15px;

    span {
        font-weight: 500;
    }

    ul {
        li {
            color: #676D7D;
            padding: 4px 0 4px 10px;
            position: relative;

            &:before {
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 100%;
                background: #676D7D;
                position: absolute;
                left: 0;
                top: 12px;
            }
        }
    }
}

.calenderBox {
    border: 1px solid #ccc;

    .modeWrapper {
        button {
            &:first-child {
                display: none;
            }
        }
    }

    .calendarHeader {
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;
        position: relative;

        h1 {
            font-size: 30px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;

            @include respond-to(media-xs) {
                font-size: 18px;
            }
        }

        .calendarHeaderButtons {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding: 0;
            margin: 0;

            button {
                height: 50px;
                padding: 0 15px;
            }
        }
    }

    .calendarDayOfWeek {
        border-bottom: 1px solid #ccc;

        >div {
            padding: 10px;
            font-weight: 700;
            border-right: 1px solid #ccc;

            &:last-child {
                border: none;
            }
        }
    }

    .weekRow {
        .dayCell {
            padding: 10px;
            border-right: 1px solid #ccc;

            &:last-child {
                border: none;
            }
        }

        .currentDay {
            background: $primary;
            width: 30px;
            height: 30px;
        }

        .dayCellEvent {
            height: 25px !important;

            .eventTitle {
                line-height: 25px;
            }
        }
    }

    .dailyEventWrapper {
        line-height: 25px;
    }
}

.vendorsList {
    margin: -10px;

    li {
        padding: 10px;
        width: 25%;

        @include respond-to(media-xl) {
            width: 33.33%;
        }

        @include respond-to(media-md) {
            width: 50%;
        }

        @include respond-to(media-xs) {
            width: 100%;
        }

        .inner {
            background: #FFFFFF;
            box-shadow: 0px 6px 20px #0000000D;
            border: 1px solid #636303;
            border-radius: $borderRad;
            height: 100%;

            .logo {
                border-bottom: 1px solid #ddd;
                height: 80px;
                padding: 5px 0;
                position: relative;

                img {
                    max-height: 100%;
                    max-width: 100%;
                }

                .more {
                    color: #bbb;
                    font-size: 24px;
                    padding: 0;
                    background: no-repeat;
                    border: none;

                    &:after {
                        display: none;
                    }
                }

                .dropdown-menu.show {
                    margin-top: 18px;
                    padding: 0;
                    left: inherit !important;
                    right: 15px !important;
                    min-width: 100px;

                    a {
                        padding: 0;
                        border-bottom: 1px solid #eee;
                        padding: 10px 15px;
                        display: block;
                        font-size: 14px;

                        .icon {
                            font-size: 20px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }

            .det {
                .name {
                    font-size: 20px;
                    color: #000;
                    font-weight: 600;
                }

                .info {
                    font-size: 15px;
                    font-weight: 600;

                    .icon {
                        color: #FF854C;
                        font-size: 24px;
                        min-width: 24px;
                    }
                }
            }
        }
    }
}

.uploadVedorLogo {
    background: #FFFFFF;
    padding: 10px;
    width: 250px;
    height: 150px;
    border: 1px dashed #B2B2B2;
    border-radius: $borderRad;
    margin: auto;
    position: relative;
    overflow: hidden;

    img {
        max-width: 100%;
        height: 100%;
    }

    .text {
        color: #6E6E6E;
        font-size: 15px;
        font-weight: 500;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 10px auto;
            width: 40px;
            height: 40px;
            background: #3da4b4;
            border-radius: 100%;
            color: #fff;
            font-size: 20px;
        }
    }

    .file {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 50px;
        opacity: 0;
    }

    .upload {
        position: absolute;
        right: 5px;
        bottom: 5px;
        width: 40px;
        height: 40px;
        overflow: hidden;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 10px auto;
            width: 40px;
            height: 40px;
            background: #3da4b4;
            border-radius: 100%;
            color: #fff;
            font-size: 20px;
        }
    }
}

a.bookBtn {
    display: inline-block;
    background: #FEB602;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    padding: 10px 20px;

    &:hover {
        background: #333;
    }
}

.vehicleImg {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.vandorInfo {
    ul {
        @include respond-to(media-sm) {
            display: block !important;
        }

        li {
            margin-left: 30px;
            font-size: 15px;
            font-weight: 600;

            @include respond-to(media-sm) {
                margin-left: 10px;
                font-size: 14px;
            }

            .icon {
                color: #FF854C;
                margin-right: 5px;
                font-size: 24px;

                @include respond-to(media-sm) {
                    font-size: 20px;
                }
            }
        }
    }
}

.calederIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -8px;
    font-size: 16px;
    color: #666;
}

[aria-hidden="true"] {
    display: none;
}

.radioWrapper {
    position: relative;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 4px 0;
}

.radioInpt {
    opacity: 0;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    margin: 0;
}

.radioInpt+.bg {
    height: 24px;
    width: 24px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #707070;
    background: #fff;
    border-radius: 100%;
}

.radioInpt:checked+.bg {
    background: #F0B342;
    border: 1px solid #F0B342;
    box-shadow: 0 0 0 2px inset #fff;
}

.reepatEvery {
    .formInput {
        width: 25%;
        margin-right: 10px;
        text-align: center;
    }

    .formSelect {
        width: 75%;
        max-width: 150px;
    }
}

.bookingDetBox {
    border: 1px solid #D8D8D8;
    border-radius: $borderRad;

    .hd {
        display: block;
        font-size: 17px;
        font-weight: 600;
    }

    .detInfo {
        li {
            font-size: 15px;
            padding: 4px 0;
            color: #232E35;
            line-height: 24px;

            span {
                font-weight: 600;
                margin-right: 5px;
            }
        }
    }
}


.weekList {
    li {
        width: 46px;
        height: 46px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background: #ccc;
            border-color: #ccc;
        }

        &.active {
            background: #0188FF;
            color: #fff;
            border-color: #0188FF;
        }
    }
}

.uploadText {
    margin-top: 8px;

    img {
        width: 60px;
        margin-right: 10px;
    }
}

.cancelbtn {
    padding: 12px;
    border-radius: 50%;
    background-color: #333;
    color: white;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -15px;
    right: -8px;
}

.cancelbtn:hover {
    background-color: #f0b342;
}

.react-pdf__Document {height: 70vh; overflow: auto; width: 100%;}
.react-pdf__Document canvas {margin: auto;}
#msdoc-renderer iframe {height: 70vh !important;}

.downloadBtn{
    background-color: pink;
    // right: 45px !important;
}

// 2024-05-14T06:27:25.166Z
// 2024-05-14T06:23:49.974Z