@import "../assets/css/mixin.scss";
.dbTop {
  margin: -10px;
  @include respond-to(media-md) {
    margin: -5px;
  }
  li {
    padding: 10px;
    @include respond-to(media-md) {
      padding: 5px;
    }
    .inner {
      border: 1px solid #eaeae1;
      border-radius: $borderRad;
      padding: 20px;
      height: 100%;
      @include respond-to(media-md) {
        padding: 10px;
      }
      .hd {
        font-size: 20px;
        font-weight: 600;
        @include respond-to(media-md) {
          font-size: 18px;
        }
        .icon {
          font-size: 30px;
        }
        svg {
          path {
            fill: #000;
          }
        }
      }
      .price {
        color: #8a8a8a;
        font-size: 15px;
        font-weight: 500;
        @include respond-to(media-md) {
          font-size: 14px;
        }
        span {
          font-size: 30px;
          font-weight: 700;
          color: #363d4d;
          @include respond-to(media-md) {
            font-size: 22px;
          }
        }
      }
    }
  }
}
.aisBox {
  background: #ffffff;
  box-shadow: 0px 6px 20px #0000000d;
  border: 1px solid #eaeae1;
  border-radius: $borderRad;
  padding: 20px;
}

.IncomeChart {
  svg {
    path {
      fill: #ffc2a5;
    }
  }
}


.notificationBox {position: relative;
  .notificationPoup {position: absolute; right: 0; top: 100%; width: 300px; background: #fff; box-shadow: 0 0 10px rgba(0,0,0,.2); margin-top: 15px; max-height: 260px; overflow: auto;
    &::-webkit-scrollbar {width: 6px; height: 6px;}
    &::-webkit-scrollbar-track { background: #e5e5e5; }
    &::-webkit-scrollbar-thumb { background: #ccc; }
    &::-webkit-scrollbar-thumb:hover { background: #000;}
    @include respond-to(media-sm) {right: -100px;}
    ul {
      li {border-bottom: 1px solid #eee;
        a {padding: 10px 15px; display: block; font-size: 14px;
          span {display: block; color: #FF0000; font-size: 16px; padding-bottom: 5px;}
          &:hover {background: #f4f4f4;}
        }
      }
    }
  }
}
