/*Media Query Mixing*/
@mixin respond-to($breakpoint) {
  @if $breakpoint == "media-xs" {
    @media (max-width: 575px) {
      @content;
    }
  }
  @if $breakpoint == "media-sm" {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == "media-md" {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $breakpoint == "media-lg" {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $breakpoint == "media-xl" {
    @media (max-width: 1499px) {
      @content;
    }
  }
}
$primary: #f0b342;
$darkGray: #333;
$whtClr: #fff;
$primary-font: "Inter", sans-serif;
$borderRad: 12px;
