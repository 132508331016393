@import "../assets/css/mixin.scss";
.outerBox {
  border-radius: $borderRad;
  padding: 20px;
  border: 1px solid #eaeae1;
  box-shadow: 0px 6px 20px #0000000d;
  @include respond-to(media-md) {
    padding: 0;
    border: none;
    box-shadow: none;
  }
}
.addImg {
  margin: -10px;
  @include respond-to(media-md) {
    margin: -5px;
  }
  li {
    padding: 10px;
    @include respond-to(media-md) {
      padding: 5px;
    }
    .upload {
      border: 1px dashed #b2b2b2;
      border-radius: $borderRad;
      position: relative;
      color: #6e6e6e;
      cursor: pointer;
      overflow: hidden;
      .addicon {
        display: block;
        font-size: 48px;
      }
      .addBtn {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
      .file {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 200px;
        height: 100%;
        opacity: 0;
      }
      img {
        width: 100%;
        border-radius: $borderRad;
      }
      .editBtn {
        position: absolute;
        font-size: 20px;
        right: 10px;
        bottom: 10px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        padding: 0;
        background: $darkGray;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: $primary;
        }
      }
    }
  }
}
