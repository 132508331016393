@import "../assets/css/mixin.scss";
.DetailSlider {
  img {
    width: 100%;
    border-radius: $borderRad;
  }
  .slick-arrow {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #fff;
    z-index: 10;
    &.slick-prev {
      left: 10px;
      &:after {
        transform: rotate(90deg);
      }
    }
    &.slick-next {
      right: 10px;
      &:after {
        transform: rotate(-90deg);
      }
    }
    &:before {
      display: none;
    }
    &:after {
      content: "";
      background: url(../assets/images/angle-right.svg) no-repeat center;
      width: 40px;
      height: 40px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.vehicleInfo {
  padding: 10px;
  height: 100%;
  .map {
    width: 50%;
    border-radius: $borderRad;
    overflow: hidden;
    @include respond-to(media-xs) {
      width: 100%;
    }
  }
  .detail {
    width: 50%;
    padding: 0 15px 0 30px;
    @include respond-to(media-lg) {
      padding: 0 10px 0 20px;
    }
    @include respond-to(media-xs) {
      width: 100%;
      padding: 15px 0 0 0;
    }
    ul {
      li {
        color: #232e35;
        padding: 10px 0;
        @include respond-to(media-lg) {
          padding: 7px 0;
        }
        span {
          font-weight: 600;
        }
      }
    }
  }
}

.driverInfo {
  ul {
    li {
      width: 25%;
      border-left: 1px solid #dbdbdb;
      padding: 0 30px;
      @include respond-to(media-lg) {
        padding: 0 15px;
      }
      @include respond-to(media-md) {
        padding: 10px 15px;
        width: 50%;
        border-top: 1px solid #dbdbdb;
        &:nth-child(2) {
          border-top: none;
        }
        &:nth-child(3) {
          border-left: none;
        }
      }
      @include respond-to(media-xs) {
        width: 100%;
        padding: 6px 0;
        border: none;
      }
      &:first-child {
        border: none;
        padding-left: 0;
      }
      .driverImg {
        width: 120px;
        @include respond-to(media-lg) {
          width: 80px;
        }
        img {
          border-radius: 100%;
          width: 100%;
        }
      }
      .det {
        width: calc(100% - 120px);
        @include respond-to(media-lg) {
          width: calc(100% - 80px);
        }
      }
      span {
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }
}

.serviceRem {
  li {
    background: #f8f8f8;
    border-radius: $borderRad;
    .left {
      font-weight: 600;
    }
  }
}
.lastCheckin {
  font-size: 18px;
  span {
    display: block;
    font-weight: 600;
  }
}
