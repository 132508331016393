@import "../assets/css/mixin.scss";
.dashboardLeft {
  width: 300px;
  height: 100%;
  z-index: 1001;
  position: fixed;
  left: 0;
  top: 0;
  background: #f8f8fd url(../assets/images/menu-bg.png) repeat-x bottom center;
  .top {
    height: calc(100vh - 75px);
    overflow: auto;
    @include respond-to(media-lg) {
      height: calc(100vh - 160px);
    }
  }
  @include respond-to(media-lg) {
    display: none;
  }
  &.open {
    @include respond-to(media-lg) {
      display: block;
      height: calc(100% - 85px);
      top: 85px;
      left: 15px;
      width: calc(100% - 30px);
    }
  }
}
.dbLogo {
  padding: 25px;
  img {
    width: 155px;
  }
  @include respond-to(media-lg) {
    display: none;
  }
}
.dashboardMenu {
  padding: 0 25px 25px 25px;
  margin: 0;
  @include respond-to(media-lg) {
    padding: 15px 25px 25px 25px;
  }
  li {
    margin-bottom: 1px;
    a {
      color: #727782;
      font-size: 16px;
      font-weight: 500;
      height: 56px;
      display: flex;
      align-items: center;
      border-radius: $borderRad;
      padding: 0 20px;
      svg {
        margin-right: 15px;
        font-size: 22px;
        path {
          fill: #727782;
        }
      }
      &:hover,
      &.active {
        background: #363d4d;
        color: #fff;
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

.mobMenu {
  display: none;
  @include respond-to(media-lg) {
    display: block;
    position: fixed;
    right: 20px;
    top: 14px;
    z-index: 102;
    font-size: 40px;
  }
}
.addVehicleMob {
  display: none;
  @include respond-to(media-sm) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    background: $primary;
    border-radius: $borderRad;
    height: 55px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }
}

.dbFooter {
  height: 75px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  border-top: 1px solid #fff;
}
