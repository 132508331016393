@import "../assets/css/mixin.scss";
.emptyBox {
  min-height: calc(100vh - 250px);
}
.bankList {
  li {
    border: 1px solid #eaeae1;
    border-radius: 6px;
    background: #fbf7f3;
    padding: 15px 30px;
    margin-bottom: 10px;
    @include respond-to(media-sm) {
      padding: 10px 15px;
    }
    &.last {
      background: #f8f9fa;
      border-color: #cbe6eb;
    }
    .removeBtn {
      border: 1px solid #a2a2a2;
      border-radius: $borderRad;
      height: 44px;
      background: none;
      color: #363d4d;
      font-weight: 500;
      font-size: 14px;
      padding: 0 20px;
      width: 150px;
      &:hover {
        background: $darkGray;
        color: #fff;
      }
      @include respond-to(media-sm) {
        width: 120px;
        height: 40px;
        border-radius: 6px;
        padding: 0 10px;
      }
    }
    .smallBtn {
      width: 150px;
      height: 44px;
      width: 150px;
      @include respond-to(media-sm) {
        width: 120px;
        height: 40px;
        border-radius: 6px;
      }
    }
    .bankImg {
      width: 100px;
      min-width: 100px;
      height: 50px;
      @include respond-to(media-sm) {
        height: auto;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .cardDet {
      font-size: 15px;
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .addBank {
      font-weight: 500;
      .icon {
        font-size: 26px;
      }
    }
  }
}
