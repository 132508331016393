@import "../assets/css/mixin.scss";
.loginLeft {
  width: 50%;
  height: 100vh;
  @include respond-to(media-md) {
    width: 100%;
  }
}
.loginBg {
  background: url(../assets/images/login-img.jpg) no-repeat center;
  background-size: cover;
  width: 50%;
  height: 100vh;
  @include respond-to(media-md) {
    display: none;
  }
}
.loginTop {
  height: calc(100vh - 60px);
  padding: 15px 20px;
  overflow: auto;
  background: #fff url(../assets/images/login-bg.jpg) repeat-x center bottom;
  @include respond-to(media-md) {
    min-height: calc(100vh - 60px);
    height: auto;
  }
}
.loginFooter {
  background: #eeeee6;
  font-size: 14px;
  line-height: 20px;
  height: 60px;
  padding: 10px;
  width: 100%;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.logininner {
  max-width: 500px;
  width: 100%;
  margin: auto;
}
.loginHd {
  font-size: 50px;
  font-weight: 700;
  @include respond-to(media-sm) {
    font-size: 40px;
  }
}
.loginText {
  font-size: 18px;
  line-height: 28px;
  &.fpText {
    max-width: 400px;
    margin: auto;
  }
  @include respond-to(media-sm) {
    font-size: 16px;
    line-height: 24px;
  }
  a {
    color: $primary;
    &:hover {
      text-decoration: underline;
    }
  }
}
.rem {
  font-size: 15px;
  font-weight: 500;
}
a.fpLink {
  color: $primary;
  font-size: 15px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
.loginBtn {
  width: 100%;
  height: 55px;
  font-size: 16px;
  font-weight: 500;
  border-radius: $borderRad;
  padding: 0 30px;
  &.auto {
    width: auto;
  }
}

.popupBox {
  min-height: 200px;
}
.fpHd {
  font-size: 30px;
  font-weight: 700;
  @include respond-to(media-sm) {
    font-size: 22px;
  }
}
